import * as d3 from 'd3';
import * as topojson from 'topojson';

window.d3 = d3;
var csv;
var circles;
var hulls;
const blur = 3;
var maxsize = 4;
var duration = 2000;

var mixedextent;

const col = d3.interpolateCool;

var width = window.innerWidth, height = window.innerHeight;

var projection = d3
  .geoAlbers ()
  .center ([0.1, 54.4])
  .rotate ([4.4, 0])
  .parallels ([50, 60])
  .scale (1200 * 5)
  .translate ([width / 2, height / 2]);

var path = d3.geoPath ().projection (projection).pointRadius (2);

var map = d3
  .select ('body')
  .append ('svg')
  .attr ('width', width)
  .attr ('height', height);

var defs = map.append ('defs');
var filter = defs.append ('filter').attr ('id', 'gooey');
filter
  .append ('feGaussianBlur')
  .attr ('in', 'SourceGraphic')
  .attr ('stdDeviation', blur)
  .attr ('result', 'blur');
filter
  .append ('feColorMatrix')
  .attr ('in', 'blur')
  .attr ('mode', 'matrix')
  .attr ('values', '1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 30 -3')
  .attr ('result', 'gooey');
filter
  .append ('feComposite')
  .attr ('in', 'SourceGraphic')
  .attr ('in2', 'gooey')
  .attr ('operator', 'atop');

d3.json (
  'https://raw.githubusercontent.com/deldersveld/topojson/master/countries/united-kingdom/uk-counties.json',
  function (error, uk) {
    console.log (uk);
    var subunits = topojson.feature (uk, uk.objects.GBR_adm2);
    //       places = topojson.feature(uk, uk.objects.places);

    map
      .selectAll ('.bgmap')
      .data (subunits.features)
      .enter ()
      .append ('path')
      .attr ('class', 'bgmap')
      .attr ('d', path);

    var gooey = map.append ('g').style ('filter', 'url(#gooey)');

    d3.csv ('plotdata.csv', c => {
      console.log (c);

      csv = c;

      circles = gooey
        .selectAll ('.cities')
        .data (c)
        .enter ()
        .append ('svg:circle')
        .attr ('class', 'point')
        // .style('mix-blend-mode','multiply')
        // .attr('opacity',0.3)
        .attr ('transform', function (d) {
          //console.log(d);
          return 'translate(' + projection ([+d.lat, +d.lng]) + ')';
        })
        .attr ('r', 1);

      mixedextent = d3.extent ([
        ...csv.map (d => +d['in_degree']),
        ...csv.map (d => +d['out_degree']),
      ]);

      hulls = map.append ('g');

      [...new Set (csv.map (d => d['louvain']))].map (h => {
        var vertices = csv
          .filter (d => d['louvain'] === h)
          .map (d => projection ([+d.lat, +d.lng]));

        hulls
          .style ('stroke-width', 0)
          .attr ('fill', 'none')
          .append ('path')
          .attr ('class', 'hull')
          .datum (d3.polygonHull (vertices))
          .attr ('d', function (d) {
            return 'M' + d.join ('L') + 'Z';
          })
          .attr ('stroke', d => d3.schemeCategory20[+h]);
      });

      update (0);
    });
  }
);

let scene = 0;
let timer = Date.now ();

async function debounce_leading (e) {
  const now = await Date.now ();
  if (now - timer > 2000) {
    timer = now;
    console.log ('start', e);

    scene += Math.sign (e.deltaY) * 1;
    console.warn ('scene:', scene);

    scene >= 0 ? update (scene) : (scene = 0);

    // d3.selectAll ('.point').attr ('r', r => scene);
  } else {
    // 			console.log(skip)
  }
}

document.removeEventListener ('mousewheel', debounce_leading);
document.addEventListener ('mousewheel', debounce_leading);

// console.log (spectral (0));

function update (state) {
  let what, scale;
  switch (state) {
    case 0:
      what = 'out_degree';
      scale = d3
        .scalePow ()
        .exponent (0.25)
        .range ([0, 1])
        .domain (mixedextent);

      circles
        .transition ()
        .duration(duration)
        .ease (d3.easePoly)
        .style ('fill', r => col (scale (r[what])))
        .attr ('r', r => 1 + scale (+r[what]) * maxsize)
        .attr ('opacity', r => 0.5 + scale (+r[what]) * 0.8);

      describe (
        'Travelling From',
        'We begin by exploring the origin of everyones journeys to work. This gives us an idea from which Local Authority Districts people travel the most.',
        '(which areas do we leave the most)'
      );
      break;
    ////////////////////////////////
    case 1:
      what = 'in_degree';
      scale = d3
        .scalePow ()
        .exponent (0.25)
        .range ([0, 1])
        .domain (mixedextent);

      circles
        .transition ()
        .duration(duration)
        .ease (d3.easePoly)
        .style ('fill', r => col (scale (r[what])))
        .attr ('r', r => 1 + scale (+r[what]) * maxsize)
        .attr ('opacity', r => 0.5 + scale (+r[what]) * 0.8);

      describe (
        'Travelling To',
        'Next we look at where people go to work. This can give us an indication of where the jobs are located.',
        '(which areas do we go into)'
      );
      break;

    case 2:
      what = 'change';
      var ex = d3.extent (csv.map (d => +d[what]));
      var lim = d3.max (ex.map (Math.abs));
      scale = d3
        .scalePow ()
        .exponent (0.2)
        .range ([1, 0, 1])
        .domain ([-lim, 0, lim]);

      var c2 = d3
        .scaleLinear ()
        .range (['purple', 'black', 'coral'])
        .domain ([ex[0], 0, ex[1]]);

      circles
        .transition ()
        .duration(duration)
        .ease (d3.easePoly)
        .style ('fill', r => c2 (r[what]))
        .attr ('r', r => scale (+r[what]) * maxsize);

      describe (
        'Change',
        'We can also look at the Net change in people.  ',
        'The greatest increase (orange) occurs in central london, with the greatest loss occuring in the commuter belt (purple).'
      );
      break;
    case 3:
      what = 'km_out_degree';
      scale = d3
        .scalePow ()
        .exponent (0.7)
        .range ([0, 1])
        .domain (d3.extent (csv.map (d => +d[what])));

      circles
        .transition ()
        .duration(duration)
        .ease (d3.easePoly)
        .style ('fill', r => col (scale (r[what])))
        .attr ('r', r => 1 + scale (+r[what]) * maxsize)
        .attr ('opacity', r => 0.5 + scale (+r[what]) * 0.8);

      describe (
        'Distance Travelled From',
        'Where do people travel the most from to get to work. These are areas that could potentially be expored for the introduction of industry.',
        'calculated as the distance * number of people'
      );
      break;
    case 4:
      what = 'km_in_degree';
      scale = d3
        .scalePow ()
        .exponent (0.25)
        .range ([0, 1])
        .domain (d3.extent (csv.map (d => +d[what])));

      circles
        .transition ()
        .duration (duration)
        .ease (d3.easePoly)
        .style ('fill', r => col (scale (r[what])))
        .attr ('r', r => 1 + scale (+r[what]) * maxsize)
        .attr ('opacity', r => 0.5 + scale (+r[what]) * 0.8);

      hulls
        .transition ()
        .duration (duration)
        .ease (d3.easePoly)
        .style ('stroke-width', 0);

      describe (
        'Distance Travelled To',
        
        'In looking at the distance people travel from an area, we can build an idea of which areas are most desirable - such that people will travel the further to get to them. ',
        'calculated as the distance * number of people'
      );
      break;
    case 5:
      what = 'louvain';

      hulls
        .transition ()
        .duration (duration)
        .ease (d3.easePoly)
        .style ('stroke-width', 3);

      circles
        .transition ()
        .duration (duration)
        .ease (d3.easePoly)
        .attr ('r', 0);

      describe (
        'Commuting Cliques',
        'Using network analysis we are able identify a number of commuting clusters across the nation. Based on the commuting patterns of people, these are often cities which have their own commuting belt comprised by their direct neighbours. ',
        ''
      );
      break;
    case 6:
      what = 'betweenness_centrality';
      scale = d3
        .scalePow ()
        .exponent (0.95)
        .range ([0, 1])
        .domain (d3.extent (csv.map (d => +d[what])));

      hulls
        .transition ()
        .duration (duration )
        .ease (d3.easePoly)
        .style ('stroke-width', 1);

      circles
        .transition ()
        .duration (duration)
        .ease (d3.easePoly)
        .style ('fill', r => col (scale (r[what])))
        .attr ('r', r => 1 + scale (+r[what]) * maxsize)
        .attr ('opacity', r => 0.5 + scale (+r[what]) * 0.8);

      describe (
        'Betweenness',
        'Similarly we can locate areas binding multiple commuting hubs ',
        'These areas can act as bottlenecks for anyone needing to travel between two work areas.'
      );
      break;
    case 7:
      what = 'eigenvector_centrality';
      scale = d3
        .scalePow ()
        .exponent (0.35)
        .range ([0, 1])
        .domain (d3.extent (csv.map (d => +d[what])));

      hulls
        .transition ()
        .duration (duration )
        .ease (d3.easePoly)
        .style ('stroke-width', 1);

      circles
        .transition ()
        .duration (duration )
        .ease (d3.easePoly)
        .style ('fill', r => col (scale (r[what])))
        .attr ('r', r => 1 + scale (+r[what]) * maxsize)
        .attr ('opacity', r => 0.5 + scale (+r[what]) * 0.8);

      describe (
        'Predicting travel to work',
        'Finally we can look at the flow of people in and out of areas, and their destination, to predict where the next most popular work destinations are likely to be.',
        'This relies on a set eigenvector based algorithms which determine the transitive influence of nodes in a network. '
      );
      break;
    default:
      console.warn ('default case resetting to max');
      scene = 7;
    

      describe ('Avarage distance travelled (km)', `
      car
      19.671788165503003

      <br><br>
      public
      14.248824568510184
      <br><br>
      motorbike
      17.524026676780487
      <br><br>
      train
      42.918892027793106
      <br><br>
      bike
      9.212747970033197
      <br><br>
      walk
      7.2604354076721895`,'This is the end');
      break;
  }
}

// betweenness_centrality: "0.005628581058308056"
// cd: "E41000201"
// change: "-3436"
// eigenvector_centrality: "0.009739389292496658"
// in_degree: "17327"
// km_in_degree: "752595.0476457938"
// km_out_degree: "1050276.1135107162"
// lat: "-0.9198451119631752"
// lng: "53.1239575573819"
// louvain: "0"
// nm: "Newark and Sherwood"
// out_degree: "20763"

// let powerScale = d3.scalePow()
//   .exponent(0.5)
//   .domain([0, 100])
//   .range([0, 30]);

const desc = d3.select ('#description');
function describe (title, subtitle, text) {
  //  change the text description
  desc
    .transition ()
    .duration (duration / 3)
    .style ('opacity', 0.1)
    .on ('end', () => {
      console.log ('end', desc);
      desc.node ().innerHTML = `
  <h1>${title}</h1><h3>${subtitle.replace (/[^0-9A-Za-z ]/g, c => '&#' + c.charCodeAt (0) + ';')}</h3>
<p>${text.replace (/[^0-9A-Za-z ]/g, c => '&#' + c.charCodeAt (0) + ';')}</p>
  `;
    })
    .transition ()
    .duration (duration / 3)
    .style ('opacity', 1);
}

// const spectral = d3
//   .scaleLinear ()
// //   .exponent(.001)
//   .domain (d3.range (0, 1, 0.1))
//   .range ([
//     '#2c7bb6',
//     '#00a6ca',
//     '#00ccbc',
//     '#90eb9d',
//     '#ffff8c',
//     '#f9d057',
//     '#f29e2e',
//     '#e76818',
//     '#d7191c',
//   ]);
